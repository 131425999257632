<template>
  <page-head-wrapper>
    <div class="mp-search-wrap-2">
      <a-form-model
        label-align="left"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
				:form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="手机号/昵称">
              <a-input placeholder="请输入应用名称" v-model="searchForm.keyword"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="企业名称">
              <a-input placeholder="请输入企业名称" v-model="searchForm.companyName"></a-input>
            </a-form-model-item>
          </a-col>
          <template v-if="searchBox.expand">
            <a-col :span="8">
              <a-form-model-item label="企业类型" :wrapper-col="{ span: 18 }">
                <a-select placeholder="请选择企业类型" v-model="searchForm.companyType"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1"
                    >已关联企业</a-select-option
                  >
                  <a-select-option value="0"
                    >未关联企业</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="账户来源">
                <a-select placeholder="请选择账户来源" v-model="searchForm.accountSource"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
                  <a-select-option value="">全部</a-select-option>
									<a-select-option :value="name" v-for="(value, name) in userSources" :key="value">
										{{ value }}
									</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="账户状态">
                <a-select placeholder="请选择账户状态" v-model="searchForm.accountState"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="y">有效</a-select-option>
                  <a-select-option value="n">无效</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="注册时间" :wrapper-col="{ span: 18 }">
                <a-range-picker v-model="searchForm.registerTimeRange" style="width: 100%"
                  :getCalendarContainer="node => node.parentNode || document.body"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="关联应用">
                <a-select
                  mode="multiple"
                  placeholder="请选择关联应用"
									v-model="searchForm.relatedApplications"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
									<a-select-option :value="name" v-for="(value, name) in relatedApps" :key="value">
										{{ value }}
									</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="最后登录时间">
                <a-range-picker v-model="searchForm.loginTimeRange" style="width: 100%"
                  :getCalendarContainer="node => node.parentNode || document.body"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
            </a-col>
          </template>
          <a-col :span="searchBox.expand ? 24 : 8">
            <div class="search-btns">
              <a-button class="mp-grey-btn" html-type="submit"> 查询 </a-button>
              <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
                重置
              </a-button>
              <a
                style="margin-left: 8px"
                v-if="!searchBox.expand"
                @click="searchBox.expand = true"
              >
                展开 <a-icon type="down" />
              </a>
              <a
                style="margin-left: 8px"
                v-if="searchBox.expand"
                @click="searchBox.expand = false"
              >
                收起 <a-icon type="up" />
              </a>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <!-- 列表 -->
    <div class="mp-table-wrap" style="margin-top: 24px">
      <a-table
        :columns="columns"
        :scroll="{ x: 1300 }"
        :data-source="data"
        rowKey="userId"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <div slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </div>
        <span slot="userNickname" slot-scope="text">
          {{text || '-'}}
        </span>
        <a slot="lastLoginTime" slot-scope="text, record" @click="toLoginLog(record.userId)">{{
          text
        }}</a>
        <div slot="status" slot-scope="text">
          <a-badge v-if="text === 'y'" color="#52C41A" text="有效" />
          <a-badge v-if="text === 'n'" color="#CCCCCC" text="无效" />
        </div>
        <div slot="handles" slot-scope="text, record">
          <a-button
            v-if="record.userStatus == 'n'"
            type="link"
            size="small"
            @click="changeStatus('y', record)"
            >启用</a-button
          >
          <a-button
            v-if="record.userStatus == 'y'"
            type="link"
            size="small"
            @click="changeStatus('n', record)"
            >禁用</a-button
          >
          <a-button type="link" size="small" @click="resetPwd(record.userId)"
            >重置密码</a-button
          >
          <a-button type="link" size="small" @click="openEditNickModal(record)"
            >编辑昵称</a-button
          >
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="editNickVisible"
      centered
      title="编辑昵称"
      @ok="editNickOk"
      :maskClosable="false" 
      :destroyOnClose="true"
      :ok-button-props="{ props: { loading: this.editNickLoading } }"
    >
      <a-form-model :model="nickForm" :rules="nickRules" ref="nickForm" 
        :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }"
      >
        <a-form-model-item label="手机号码">
          <a-input :value="nickForm.phone" disabled />
        </a-form-model-item>
        <a-form-model-item label="昵称" prop="nick">
          <a-input placeholder="请输入昵称" :maxLength="50" v-model="nickForm.nick" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { userList, resetPwd, updateState, updateNickname } from "@/api/user"
import { mapGetters } from 'vuex'
import moment from 'moment'
const columns = [
  {
    align: "center",
    title: "序号",
    width: "80px",
    scopedSlots: { customRender: "serial" },
  },
  {
    align: "center",
    title: "手机号码",
    dataIndex: "userCellphone",
    width: 110,
  },
  {
    align: "center",
    title: "昵称",
    dataIndex: "userNickname",
    scopedSlots: { customRender: "userNickname" },
    width: 120,
  },
  {
    align: "center",
    title: "所属企业",
    dataIndex: "companyNames",
    ellipsis: true,
    width: "16%",
  },
  {
    align: "center",
    title: "关联应用",
    dataIndex: "relatedApplications",
    width: "16%",
  },
  {
    align: "center",
    title: "账户来源",
    dataIndex: "userSource",
    width: "16%",
  },
  {
    align: "center",
    title: "注册时间",
    dataIndex: "userRegisterTime",
    width: 120,
  },
  {
    align: "center",
    title: "最后登录时间",
    dataIndex: "userLastLoginTime",
    scopedSlots: { customRender: "lastLoginTime" },
    width: 120,
  },
  {
    align: "center",
    title: "账户状态",
    dataIndex: "userStatus",
    scopedSlots: { customRender: "status" },
    width: 100,
  },
  {
    align: "center",
    title: "操作",
    width: 210,
    fixed: "right",
    scopedSlots: { customRender: "handles" },
  },
];
export default {
  computed: {
    ...mapGetters(['adminId'])
	},
  data() {
    return {
      // 搜索框
      searchBox: {
        expand: false,
      },
      searchForm: {
        keyword: "",
        companyName: "",
        companyType: "",
        relatedApplications: [],
        accountSource: "",
        registerStartTime: "",
        registerEndTime: "",
        lastLoginStartTime: "",
        lastLoginEndTime: "",
        accountState: "",

				registerTimeRange: null,
				loginTimeRange: null
				
      },

      // 列表数据
      data: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true,
      },

      // 编辑昵称弹框
      editNickVisible: false,
      editNickLoading: false,
      nickForm: {
        userId: '',
        phone: '',
        nick: ''
      },
      nickRules: {
        nick: [{ required: true, message: '请输入昵称', trigger: 'blur'}]
      },

			// 关联应用(1-衣加加,2-数聚时尚,3-综合体数字云仓,4-订货系统,5-乔司地图,6-技工大赛)
			relatedApps: {
				1: '衣加加',
				2: '数聚时尚',
				3: '综合体数字云仓',
				4: '订货系统',
				5: '乔司时尚地图',
				6: '技工大赛',
			},
			/*
				账户来源(0-未知,1-衣加加APP注册,2-订货系统前台注册,3-数聚时尚信息平台前台注册,4-技工大赛前台,
				5-乔司时尚地图小程序注册,6-综合体数字云仓用户,7-衣加加云（PC端）注册,
				50-得体导入,51-得体生产加工类订单导入时生成的生产商)
				10 - 服装产业云平台
			*/
			userSources: {
				0: '未知',
				1: '衣加加APP',
				2: '订货系统',
				3: '数聚时尚',
				4: '技工大赛',
				5: '乔司时尚地图',
				6: '综合体数字云仓',
				7: '衣加加云',
				10: '服装产业云平台',
				50: '得体',
				51: '得体生产加工类订单',
			}
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        let {
          size,
          current,
          keyword,
          companyName,
          companyType,
          relatedApplications,
          accountSource,
          registerStartTime,
          registerEndTime,
          lastLoginStartTime,
          lastLoginEndTime,
          accountState,
        } = route.query;
        this.pagination.pageSize = Number(size) ? Number(size) : 10
        this.pagination.current = Number(current) ? Number(current) : 1
        this.searchForm.keyword = keyword || ""
        this.searchForm.companyName = companyName || ""
        this.searchForm.companyType = companyType || ""
        this.searchForm.accountSource = accountSource || ""
        this.searchForm.registerStartTime = registerStartTime || ""
        this.searchForm.registerEndTime = registerEndTime || ""
        this.searchForm.lastLoginStartTime = lastLoginStartTime || ""
        this.searchForm.lastLoginEndTime = lastLoginEndTime || ""

        this.searchForm.accountState = accountState || ""

				relatedApplications = relatedApplications && relatedApplications.split(',')
				this.searchForm.relatedApplications = relatedApplications || []

				if (registerStartTime && registerEndTime) {
					this.searchForm.registerTimeRange = [moment(registerStartTime), moment(registerEndTime)]
				} else {
					this.searchForm.registerTimeRange = null
				}

				if (lastLoginStartTime && lastLoginEndTime) {
					this.searchForm.loginTimeRange = [moment(lastLoginStartTime), moment(lastLoginEndTime)]
				} else {
					this.searchForm.loginTimeRange = null
				}
				
        this.getListData()
      },
      immediate: true,
    },
  },
  created() {
		// console.log(moment())
		// console.log(moment().format('YYYY-MM-DD'))
  },
  methods: {
		// 搜索查询
		handleSearchSubmit() {
			console.log(this.searchForm)
			this.pagination.current = 1
      this.currentRoutePush()
		},
		// 重置搜索
    resetSearchForm() {
      this.pagination.current = 1
      this.searchForm.keyword = ""
			this.searchForm.companyName = ""
			this.searchForm.companyType = ""
			this.searchForm.relatedApplications = []
			this.searchForm.accountSource = ""
			this.searchForm.registerStartTime = ""
			this.searchForm.registerEndTime = ""
			this.searchForm.lastLoginStartTime = ""
			this.searchForm.lastLoginEndTime = ""
			this.searchForm.accountState = ""
			this.searchForm.registerTimeRange = null,
			this.searchForm.loginTimeRange = null
      this.currentRoutePush()
    },
		// 获取用户列表
    getListData() {
      this.loading = true;
			let registerStartTime = ''
			let registerEndTime = ''
			let lastLoginStartTime = ''
			let lastLoginEndTime = ''
			try {
				if (this.searchForm.registerTimeRange) {
					registerStartTime = this.searchForm.registerTimeRange[0].format('YYYY-MM-DD') || ''
					registerEndTime = this.searchForm.registerTimeRange[1].format('YYYY-MM-DD') || ''
				}
				if (this.searchForm.loginTimeRange) {
					lastLoginStartTime = this.searchForm.loginTimeRange[0].format('YYYY-MM-DD') || ''
					lastLoginEndTime = this.searchForm.loginTimeRange[1].format('YYYY-MM-DD') || ''
				}
			} catch (error) {
				console.warn(error)
			}
			
      userList({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        keyword: this.searchForm.keyword,
        companyName: this.searchForm.companyName,
        companyType: this.searchForm.companyType,
        relatedApplications: this.searchForm.relatedApplications && this.searchForm.relatedApplications.join(',') || '',
        accountSource: this.searchForm.accountSource,
        registerStartTime,
        registerEndTime,
        lastLoginStartTime,
        lastLoginEndTime,
        accountState: this.searchForm.accountState,
      })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.data = res.data.records.map(item => {
							let relatedApplications = ''
							try {
								let relatedApplicationsArr = item.relatedApplications.split(',')
								relatedApplications = relatedApplicationsArr.map(a => this.relatedApps[a]).join(' , ')
							} catch (error) {
								relatedApplications = '-'
							}
							return {
								...item,
								userSource: this.userSources[item.userSource] || '-',
								companyNames: item.companyNames || '-',
								relatedApplications
							}
						});
            this.pagination.total = res.data.total;

            // 当页码过大时，没有数据时，跳转到最近的一个页面
            if (
              res.data.records.length === 0 &&
              res.data.total > 0 &&
              this.pagination.current > 1
            ) {
              this.pagination.current = Math.ceil(
                res.data.total / this.pagination.pageSize
              );
              this.currentRoutePush();
            }
          } else {
            this.$message.error(res.msg || "网络异常，请稍后重试");
          }
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 切换分页等操作
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.currentRoutePush()
    },
		// query改变
    currentRoutePush() {
      let s = new Date().getTime().toString().slice(4)
      let queryStr = 'current=' + this.pagination.current + '&size=' + this.pagination.pageSize

      if (this.searchForm.keyword !== '') {
        queryStr += '&keyword=' + this.searchForm.keyword
      }
			if (this.searchForm.companyName !== '') {
        queryStr += '&companyName=' + this.searchForm.companyName
      }
			if (this.searchForm.companyType !== '') {
        queryStr += '&companyType=' + this.searchForm.companyType
      }
			if (this.searchForm.relatedApplications && this.searchForm.relatedApplications.length > 0) {
				let relatedApplications = this.searchForm.relatedApplications.join(',')
        queryStr += '&relatedApplications=' + relatedApplications
      }
			if (this.searchForm.accountSource !== '') {
        queryStr += '&accountSource=' + this.searchForm.accountSource
      }
			if (this.searchForm.accountState !== '') {
        queryStr += '&accountState=' + this.searchForm.accountState
      }

			if (this.searchForm.registerTimeRange && this.searchForm.registerTimeRange.length > 0){
				queryStr += '&registerStartTime=' + this.searchForm.registerTimeRange[0].format('YYYY-MM-DD')
				queryStr += '&registerEndTime=' + this.searchForm.registerTimeRange[1].format('YYYY-MM-DD')
			}
			if (this.searchForm.loginTimeRange && this.searchForm.loginTimeRange.length > 0){
				queryStr += '&lastLoginStartTime=' + this.searchForm.loginTimeRange[0].format('YYYY-MM-DD')
				queryStr += '&lastLoginEndTime=' + this.searchForm.loginTimeRange[1].format('YYYY-MM-DD')
			}
      queryStr += '&s=' + s
      this.$router.push('/basic-data/user/list?' + queryStr) 
      .catch(err => {err})
    },

    // 禁用/启用用户
    changeStatus(status, record) {
			let tip = status === 'y' ? '启用' : '禁用';
      this.$confirm({
        title: `确认${tip}该用户(${record.userCellphone})吗？`,
        // content: h => <div>Some descriptions</div>,
        centered: true,
        onOk: () => {
          console.log("OK")
					this.$showLoading()
					updateState({
						userId: record.userId,
						adminId: this.adminId,
						userState: status
					}).then(res => {
						console.log(res)
						if (res.code === 200) {
							this.$message.success(`${tip}成功`)
							this.currentRoutePush()
						} else {
							this.$message.error(res.msg || '操作失败')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.$hideLoading()
					})
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },
    // 重置密码
    resetPwd(userId) {
      this.$confirm({
        title: "确认重置该账户密码？",
				// content: "重置后，会随机生成一个新密码，并以短信告知用户",
        content: h => <div style="color: #666">重置后，会随机生成一个新密码，并以短信告知用户</div>,
        centered: true,
        onOk: () => {
          console.log("OK");
					this.$showLoading()
					resetPwd({
						userId,
						adminId: this.adminId
					}).then(res => {
						console.log(res)
						if (res.code === 200) {
							this.$message.success('密码重置成功')
						} else {
							this.$message.error(res.msg || '密码重置失败')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.$hideLoading()
					})
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },
    // 打开编辑昵称弹框
    openEditNickModal(record) {
      this.nickForm.userId = record.userId
      this.nickForm.phone = record.userCellphone || ''
      this.nickForm.nick = record.userNickname || ''
      this.editNickVisible = true
    },
    editNickOk() {
      this.$refs.nickForm.validate(valid => {
        if (valid) {
          console.log('submit')
          this.editNickLoading = true
          updateNickname({
            userId: this.nickForm.userId,
            nickname: this.nickForm.nick
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('编辑成功')
              this.editNickVisible = false
              this.currentRoutePush()
            } else {
              this.$message.error(res.msg || '操作失败')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.editNickLoading = false
          })
        }
      })
    },

    // 跳转登录日志页
    toLoginLog(userId) {
      this.$router.push("/basic-data/user/login-log?userId=" + userId)
    },
  },
};
</script>

<style>
</style>